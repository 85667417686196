/* File: src/pages/OrdifyBotLandingPage/components/test-service/AudioPlayer.css */

.rhap_play-status--paused .rhap_progress-bar {
    background-color: white;
}

.rhap_play-status--playing .rhap_progress-bar {
    background-color: white;
}

.rhap_progress-bar .rhap_progress-indicator {
    background-color: white;
}

.rhap_main-controls-button path {
    fill: white;
}
