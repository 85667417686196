@use 'styles/fonts';

body {
    margin: 0;
    font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
}

b, strong {
    font-weight: bold !important;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.app {}

.toast-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

/* ==================================== scroll bar ======================================== */

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    background-color: #9C1F1E;
}

/* ==================================== react google places  autocomplete ======================================== */

.pac-container {
    //left: 55px !important;
    z-index: 9999;
}
