.gridItemTextRight {
  @media (min-width: 320px) and (max-width: 480px) {
    padding-right: 0px !important;
    text-align: end;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    padding-right: 0px !important;
    text-align: end;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    padding-right: 60px !important;
    text-align: end;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    padding-right: 60px !important;
    text-align: end;
  }
  @media (min-width: 1201px) {
    padding-right: 60px !important;
    text-align: end;
  }
}

.gridItemTextLeft {
  @media (min-width: 375px) and (max-width: 1024px) {
    padding-left: 0px !important;
    text-align: start;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    padding-left: 0px !important;
    text-align: start;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    padding-left: 10px !important;
    text-align: start;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    padding-left: 10px !important;
    text-align: start;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    padding-left: 10px !important;
    text-align: start;
  }
  @media (min-width: 1201px) {
    padding-left: 10px !important;
    text-align: start;
  }
}

.gridItemTextCenter {
  @media (min-width: 375px) and (max-width: 1024px) {
    margin-top: 200px !important;
    margin-bottom: 100px !important;
    text-align: center;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    margin-top: 200px !important;
    margin-bottom: 100px !important;
    text-align: center;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    margin-top: 200px !important;
    margin-bottom: 100px !important;
    text-align: center;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 200px !important;
    margin-bottom: 100px !important;
    text-align: center;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    margin-top: 200px !important;
    margin-bottom: 100px !important;
    text-align: center;
  }
  @media (min-width: 1201px) {
    text-align: center;
    margin-top: 200px !important;
  }
}

.imgOrder {
  @media (min-width: 320px) and (max-width: 480px) {
    width: 300px;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 300px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 300px;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    width: 500px;
  }
  @media (min-width: 1201px) {
    width: 600px;
  }
}

.hidden {
  display: none;
}
