@font-face {
  font-family: "Nexa";
  src: url("../assets/fonts/nexa/NexaLight.otf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Nexa";
  src: url("../assets/fonts/nexa/NexaBold.otf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Goda";
  src: url("../assets/fonts/goda/GodaRegular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Goda";
  src: url("../assets/fonts/goda/GodaBold.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../assets/fonts/openSans/OpenSans-LightItalic.ttf");
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../assets/fonts/openSans/OpenSans-Light.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Open Sans";
  src: url("../assets/fonts/openSans/OpenSans-Bold.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Big Shoulders Text";
  src: url("../assets/fonts/bigShouldersText/BigShouldersText-Light.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Big Shoulders Text";
  src: url("../assets/fonts/bigShouldersText/BigShouldersText-Bold.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Brygada1918";
  src: url("../assets/fonts/brygada1918/Brygada1918-Regular.otf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Brygada1918";
  src: url("../assets/fonts/brygada1918/Brygada1918-Bold.otf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/montserrat/Montserrat-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/montserrat/Montserrat-Italic.ttf");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/montserrat/Montserrat-Bold.ttf");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/montserrat/Montserrat-BoldItalic.ttf");
  font-style: italic;
  font-weight: 700;
}
