.containerRecensioni {
  @media (min-width: 320px) and (max-width: 480px) {
    height: 100%;
    position: absolute;
    right: 1px;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    height: 100%;
    position: absolute;
    right: 1px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  @media (min-width: 1201px) {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

.carouselCustom {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100%;
  align-items: center;
}

.carousel-inner {
  height: 100%;
  align-items: center;
  display: flex;
}

.carousel-caption {
  position: relative;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.carousel-indicators {
  bottom: 80px !important
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #FFF;
}

.starsContainer {
  display: flex;
  margin-top: 50px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: url("../../../../assets/images/landing/recensioniSection/recensioni.png");
}
